import React from 'react'
import { HcpPage, SEO } from "components";

const NotFoundPage = () => (
  <HcpPage pageClassName="page-not-found">
    <SEO pageTitle="HCP Page Not Found" />
    <div className="dfa-container gutter-left gutter-right">
      <h1>PAGE NOT FOUND!</h1>
      <p>This page doesn't exist.</p>
    </div>
  </HcpPage>

)

export default NotFoundPage
